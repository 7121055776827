import { User } from '~/types/models/user';
import { Workspace } from '~/types/models/workspace';

export const useUserWorkspaces = (user: User) => {
  return (
    user?.user_workspace_roles?.reduce((acc, uwr) => {
      const workspace = uwr.workspace;
      if (!acc.find((w) => w.id === workspace.id)) acc.push(workspace);
      return acc;
    }, [] as Workspace[]) || []
  );
};
