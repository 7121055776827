import type { Garant } from '~/types/models/garant';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'garants';

const populate = {
  echeancier: true,
};

const searchFields = ['nom'];

type Api = GenericApi<Garant> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
