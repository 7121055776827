import type { ApiClient, QueryParam } from '~~/composables/useApiFactory';
import type { Document } from '~~/types/models/document';

const entityName = 'documents';

const defaultSearchFields = ['name'];

const populate = {
  media: true,
  commande: true,
};

type Api = {
  find: (
    page?: number,
    sortField?: string | string[],
    query?: string,
    limit?: number,
    filters?: object,
    populate?: object,
    searchFields?: string[],
  ) => Promise<any>;
  create: (doc: Creatable<Document>) => Promise<any>;
  delete: (id: number) => Promise<any>;
  update: (doc: Updatable<Document>) => Promise<any>;
  domaines: () => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi: Api = {
    domaines: async () => {
      const { $loading } = useNuxtApp();
      let res;
      try {
        $loading.value = true;
        res = res = await api(`${entityName}/domaines`, {});
      } catch (error) {
        console.error(error);
        res = null;
      }
      $loading.value = false;
      return res;
    },

    find: async (
      page = 1,
      sortField = '',
      query = '',
      limit = 100,
      filters: object = {},
      populate: object = { media: true },
      searchFields: string[] = defaultSearchFields,
    ) => {
      const { $loading } = useNuxtApp();
      let res;
      try {
        const params: QueryParam = {
          populate: populate,
          filters: {},
          sort: [] as string[],
          pagination: {
            page: page,
            pageSize: limit,
          },
        };
        params.filters = {
          ...(filters || {}),
        };

        if (limit === -1) {
          delete params.pagination.pageSize;
          params.pagination.limit = -1;
        }

        if (sortField) {
          if (Array.isArray(sortField)) {
            params.sort = sortField;
          } else {
            params.sort = [sortField];
          }
        }
        if (query)
          params.filters = {
            ...(params.filters || {}),
            $or: useSearchCriterias(query, searchFields),
          };
        $loading.value = true;

        res = await api(`${entityName}?${useQs(params)}`, {});
      } catch (error) {
        console.error(error);
        res = null;
      }
      $loading.value = false;
      return res;
    },

    create: async (doc: Creatable<Document>) => {
      const { $loading } = useNuxtApp();
      let res;
      try {
        const formData = new FormData();

        const docInfos = {
          name: doc.name,
          type: doc.type,
          validite: doc.validite || null,
          category: doc.category,
          construction_version: doc.construction_version || null,
          avp: doc.avp || null,
          contrat: doc.contrat || null,
          pdc: doc.pdc || null,
          financement: doc.financement || null,
          terrain_projet: doc.terrain_projet || null,
          epe: doc.epe || null,
          cci: doc.cci || null,
          garantie: doc.garantie || null,
          prepachantier: doc.prepachantier || null,
          avenant: doc.avenant || null,
          ssttfour: doc.ssttfour || null,
          terrain: doc.terrain || null,
          commande: doc.commande || null,
          metadatas: doc.metadatas || null,
          chantier: doc.chantier || null,
          domaine: doc.domaine || null,
        };

        formData.append('data', JSON.stringify(docInfos));
        formData.append(`files.media`, doc.media, crypto.randomUUID());

        const params = {
          populate: populate,
        };

        $loading.value = true;
        res = await api(`${entityName}?${useQs(params)}`, {
          method: 'POST',
          body: formData,
        });
      } catch (error) {
        res = null;
      }
      $loading.value = false;
      return res;
    },

    delete: async (id: number) => {
      const { $loading } = useNuxtApp();
      let res;
      try {
        $loading.value = true;

        const params = {
          populate: populate,
        };

        res = await api(`${entityName}/${id}?${useQs(params)}`, {
          method: 'DELETE',
        });
      } catch (error) {
        res = null;
      }
      $loading.value = false;
      return res;
    },

    update: async (doc: Updatable<Document>) => {
      const { $loading } = useNuxtApp();
      let res;
      try {
        $loading.value = true;

        const params = {
          populate: populate,
        };

        res = await api(`${entityName}/${doc.id}?${useQs(params)}`, {
          method: 'PUT',
          body: {
            data: doc,
          },
        });
      } catch (error) {
        res = null;
      }
      $loading.value = false;
      return res;
    },
  };

  return theApi;
};
