import { defineStore } from 'pinia';
import type {
  AnyMongoAbility,
  ExtractSubjectType,
  MongoQuery,
  Subject,
  SubjectRawRule,
} from '@casl/ability';
import { createMongoAbility } from '@casl/ability';
import { usePermissions } from '~~/composables/usePermissions';
import { newParamsDiffusion, type ParamsDiffusion } from '~/types/models/diffusion';
import { useUserWorkspaces } from '~/composables/users/useUserWorkspaces';
import { useUserRoles } from '~/composables/users/useUserRoles';
import type { User } from '~/types/models/user';
import type { Workspace } from '~/types/models/workspace';
import type { RouteLocationNormalized } from 'vue-router';

type State = {
  permissions?: AnyMongoAbility;
  roles: string[];
  menus: any[];
  selectedWorkspace?: Workspace;
};

export const useAuthStore = defineStore('auth-store', {
  state: (): State => ({
    permissions: undefined,
    roles: [],
    menus: [],
    selectedWorkspace: undefined,
  }),
  actions: {
    async setUserPermissions() {
      if (this.user) {
        const userPermissions = usePermissions(useUserRoles(this.user, this.workspace));
        const hasDynamicPermissions =
          this.user.dynamicPermissions && this.workspace?.id in this.user.dynamicPermissions;
        const userDynamicPermissions = hasDynamicPermissions
          ? this.user.dynamicPermissions![this.workspace.id]
          : [];

        if (userDynamicPermissions?.length) {
          userPermissions.push(
            ...userDynamicPermissions.map((p) => ({ action: p[0], subject: p[1] })),
          );
        }

        this.permissions = createMongoAbility(userPermissions);

        if (this.permissions?.rules.length)
          useNuxtApp().$ability.update(
            this.permissions.rules as SubjectRawRule<
              string,
              ExtractSubjectType<Subject>,
              MongoQuery
            >[],
          );
        else this.logout();
      }
    },

    async login(username: string, password: string) {
      const { user } = await useApi.auth.login(username, password);
      if (user.value) {
        const redirect = useCookie('redirect');
        await navigateTo(redirect.value || '/');
      }
    },
    async forgot(email: string) {
      await useApi.auth.sendForgotPasswordEmail(email);
    },
    async resetPassword(password: string, code: string) {
      return await useApi.auth.resetPassword(password, code);
    },
    logout() {
      useApi.auth.logout();
      window.location.href = '/login';
    },
    loadWorkspaceFromRoute(route: RouteLocationNormalized) {
      if (route && route.params?.workspace) {
        const workspaceSlug = route.params.workspace;
        const uws = useUserWorkspaces(this.user);
        const w = uws?.find((w) => w.slug === workspaceSlug);
        if (!w && workspaceSlug) {
          throw new Error('Workspace not found');
        }
        if (w) {
          this.selectedWorkspace = w;
          return w;
        }
      }
      return null;
    },
    loadFirstWorkspaceFromUser() {
      const uws = useUserWorkspaces(this.user);
      if (!uws?.length) return null;
      this.selectedWorkspace = uws[0];
      return this.selectedWorkspace;
    },
    switchWorkspace(id: number) {
      if (id != this.selectedWorkspace?.id) {
        const uws = useUserWorkspaces(this.user);
        const w = uws.find((w) => w.id === id);
        if (w) {
          this.selectedWorkspace = w;
          sessionStorage.removeItem('currentConfig');
          window.location.href = `/${w.slug}`;
        } else {
          console.error('Workspace not found');
          this.logout();
        }
      }
    },

    reset() {
      this.permissions = undefined;
      this.roles = [];
      this.menus = [];
      this.selectedWorkspace = undefined;
    },

    createNewParamsDiffusion() {
      this.workspace.params_diffusion = newParamsDiffusion();
      useApi.workspaces.update(this.workspace);
    },

    async refreshMe() {
      await useStrapiAuth().fetchUser();
    },
  },
  getters: {
    user(): User {
      return useStrapiUser().value as User;
    },

    workspace(): Workspace {
      return this.selectedWorkspace!;
    },
    isLoggedIn(): boolean {
      return !!this.user;
    },
    userIsRole() {
      return (role: string): boolean => {
        const roles = useUserRoles(this.user, this.workspace);
        return roles.includes(role);
      };
    },
    userIsOneOfRoles() {
      return (roles: string[]): boolean => {
        const userRoles = useUserRoles(this.user, this.workspace);
        return roles.some((role) => userRoles.includes(role));
      };
    },
    paramsDiffusion(): ParamsDiffusion | undefined {
      const p = this.workspace?.params_diffusion;
      if (!p) {
        this.workspace.params_diffusion = newParamsDiffusion();
      }
      return this.workspace?.params_diffusion;
    },
  },
});
